import React from 'react'

import { SEO } from '../components/UI'

import { NewCrystalIntro, NewCrystalActions, NewCrystalResults, NewCrystalParticipate } from '../components/NewCrystal'

export default function NovaCrystal() {
  return (
    <>
      <SEO title='A nova Crystal' />
      <NewCrystalIntro />
      <NewCrystalActions />
      <NewCrystalResults />
      <NewCrystalParticipate />
    </>
  )
}
