import React from 'react'

import { useStaticQuery, graphql } from 'gatsby'

import { Image } from '../UI'

import VectorBanner from '../../images/nova-crystal/vector-banner.svg'

import styles from './NewCrystalIntro.module.css'

function NewCrystalIntro() {
  const { image, imageMobile } = useStaticQuery(
    graphql`
      query NovaCrystalQuery {
        image: file(relativePath: { eq: "nova-crystal/bottle.png" }) {
          childImageSharp {
            fluid(maxWidth: 335) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        imageMobile: file(relativePath: { eq: "nova-crystal/bottle-mobile.png" }) {
          childImageSharp {
            fluid(maxWidth: 335) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  return (
    <div className={styles.content}>
      <section className={styles.container}>
        <div className={styles.imageWrapper}>
          <h2 className={`titleLarge ${styles.titleMobile}`}>
            a nova <br />
            crystal
          </h2>
          <figure className={styles.imageFigure}>
            <Image
              alt='Garrafa de Água Crystal'
              fluid={image.childImageSharp.fluid}
            />
          </figure>
          <figure className={styles.imageFigureMobile}>
            <Image
              alt='Garrafa de Água Crystal'
              fluid={imageMobile.childImageSharp.fluid}
            />
          </figure>
          <VectorBanner className={styles.vectorBanner} />
        </div>
        <div className={styles.textWrapper}>
          <h2 className={`titleLarge ${styles.title}`}>
            a nova <br />
            crystal
          </h2>
          <div className={styles.text}>
            <h3>O que queremos? Um futuro que seja água e ponto</h3>
            <p>
              Evoluir sempre é o que a gente quer. E o planeta, também. Qual é a nossa contribuição para que o futuro
              aconteça? Levar água por aí sem deixar resíduos.
            </p>
            <h3>Por quê? Porque o futuro só vai existir em um mundo sem resíduos</h3>
            <p>
              Até 2030, temos a meta de ajudar a coletar
              <br /> e a reciclar 1 garrafa para cada 1 que vendemos. Vamos continuar crescendo, fazendo o que
              <br /> é o certo para o planeta, para as nossas comunidades e para os nossos negócios.
            </p>
          </div>
        </div>
      </section>
    </div>
  )
}

export { NewCrystalIntro }
