import React from 'react'

import { useStaticQuery, graphql } from 'gatsby'

import { Image } from '../UI'

import styles from './NewCrystalParticipate.module.css'

function NewCrystalParticipate() {
  const { steps, mobileSteps } = useStaticQuery(
    graphql`
      query NewCrystalParticipateQuery {
        steps: file(relativePath: { eq: "nova-crystal/fluxo.png" }) {
          childImageSharp {
            fluid(maxWidth: 1602) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        mobileSteps: file(relativePath: { eq: "nova-crystal/fluxo-mobile.png" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )
  return (
    <section className={styles.content}>
      <div className={styles.article}>
        <h2 className={`titleLarge ${styles.title}`}>
          Como você pode <br />
          participar das nossas <br />
          iniciativas?
        </h2>
        <p className={styles.text}>
          <strong>Você é uma parte importante para o funcionamento da cadeia de coleta. Quer ver?</strong>
        </p>
      </div>

      <figure className={`${styles.image} ${styles.imageDesktop}`}>
        <Image
          fluid={steps.childImageSharp.fluid}
          alt='Garrafa de água mineral Crystal sem gás'
        />
      </figure>
      <figure className={`${styles.image} ${styles.imageMobile}`}>
        <Image
          fluid={mobileSteps.childImageSharp.fluid}
          alt='Garrafa de água mineral Crystal sem gás'
        />
      </figure>
      <div className={styles.textPadding}>
        <p className={styles.text}>
          Viu só? Por isso, para que ela funcione direitinho, você também precisa garantir o destino certo para as
          garrafas após seu consumo.
        </p>
        <h3 className={styles.subtitle}>Como?</h3>
        <p className={styles.text}>
          Através do catador. O descarte correto com a ajuda de catadores representa mais ou menos 90% de tudo que é
          reciclado no nosso país. Assim, para que a nossa meta se torne real (ajudar a coletar e a reciclar 1 garrafa
          para cada 1 que vendemos), ele é o elo mais importante entre você, que está aí do outro lado, e nós, que
          estamos aqui.
        </p>
        <h4 className={styles.baseTitle}>
          Unidos, nós, os catadores e você, fazemos a diferença. Vamos embarcar juntos nessa?
        </h4>
      </div>
    </section>
  )
}

export { NewCrystalParticipate }
