import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { ACCESS_WATER } from '../../constants/routes'
import { Image, Button } from '../UI'

import styles from './NewCrystalActions.module.css'

function NewCrystalActions() {
  const {
    stampDestination,
    stampIncome,
    catakiPhoto,
    catakiPhotoMobile,
    sustentapetPhoto,
    sustentapetPhotoMobile,
    waterAccess,
    availabilityWater,
    googlePlayButton,
    appStoreButton
  } = useStaticQuery(
    graphql`
      query NewCrystalActionsQuery {
        stampDestination: file(relativePath: { eq: "nova-crystal/icons/destination-waste.png" }) {
          childImageSharp {
            fluid(maxWidth: 138) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        stampIncome: file(relativePath: { eq: "nova-crystal/icons/more-income.png" }) {
          childImageSharp {
            fluid(maxWidth: 164) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        catakiPhoto: file(relativePath: { eq: "nova-crystal/cataki.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 594) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        catakiPhotoMobile: file(relativePath: { eq: "nova-crystal/cataki-mobile.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 756) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        sustentapetPhoto: file(relativePath: { eq: "nova-crystal/sustentapet.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 627) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        sustentapetPhotoMobile: file(relativePath: { eq: "nova-crystal/sustentapet-mobile.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 756) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        waterAccess: file(relativePath: { eq: "nova-crystal/agua-disponibilidade.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 706) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        availabilityWater: file(relativePath: { eq: "nova-crystal/agua-acesso.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 706) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        googlePlayButton: file(relativePath: { eq: "nova-crystal/google-play-button.svg" }) {
          publicURL
        }
        appStoreButton: file(relativePath: { eq: "nova-crystal/app-store-button.svg" }) {
          publicURL
        }
      }
    `
  )

  return (
    <section className={styles.content}>
      <div className={styles.container}>
        <h2 className={`titleLarge ${styles.title}`}>O que temos feito para isso acontecer?</h2>
        <div className={styles.article}>
          <h3>Cataki</h3>
          <div className={styles.articleContainer}>
            <div className={styles.description}>
              <h4>Oferecemos ao consumidor uma alternativa para descartar de forma correta seus resíduos</h4>
              <p className={styles.catakiText}>
                O Cataki é um app que une catadores e consumidores de um jeito bem simples. Nosso objetivo com essa
                parceria é que ele melhore cada vez mais em usabilidade e em relevância, facilitando sempre essa
                conexão. <br />
                <strong>Com a ponte entre o consumidor e o catador feita, temos:</strong>
              </p>
              <div className={styles.stamp}>
                <figure className={styles.stampIcon}>
                  <Image
                    fluid={stampDestination.childImageSharp.fluid}
                    className={styles.stampImage}
                  />
                  <figcaption>Destino certo para os resíduos</figcaption>
                </figure>
                <figure className={styles.stampIcon}>
                  <Image
                    fluid={stampIncome.childImageSharp.fluid}
                    className={styles.stampImage}
                  />
                  <figcaption>Maior renda para os profissionais</figcaption>
                </figure>
              </div>
              <nav className={styles.apps}>
                <a
                  href='https://play.google.com/store/apps/details?id=com.ionicframework.pimp473818&hl=pt_BR'
                  rel='noopener noreferrer'
                  target='_blank'
                  className={styles.google}
                >
                  <img
                    alt='Baixe na Play Store'
                    className={styles.appsImage}
                    src={googlePlayButton.publicURL}
                  />
                </a>
                <a
                  href='https://apps.apple.com/br/app/cataki/id1250990464'
                  rel='noopener noreferrer'
                  target='_blank'
                  className={styles.apple}
                >
                  <img
                    alt='Baixe na App Store'
                    className={styles.appsImage}
                    src={appStoreButton.publicURL}
                  />
                </a>
              </nav>
            </div>
            <div className={styles.image}>
              <figure className={styles.imageFigure}>
                <Image
                  alt='Catador participante do Cataki'
                  fluid={catakiPhoto.childImageSharp.fluid}
                />
              </figure>
              <figure className={styles.imageFigureMobile}>
                <Image
                  alt='Catador participante do Cataki'
                  fluid={catakiPhotoMobile.childImageSharp.fluid}
                />
              </figure>
            </div>
          </div>
        </div>
        <div className={`${styles.article} ${styles.articleSustentapet}`}>
          <h3>Sustentapet</h3>
          <div className={styles.articleContainer}>
            <div className={styles.imageLeft}>
              <figure className={styles.imageFigure}>
                <Image
                  alt='Catador com garrafa de Coca-Cola vazia'
                  fluid={sustentapetPhoto.childImageSharp.fluid}
                />
              </figure>
              <figure className={styles.imageFigureMobile}>
                <Image
                  alt='Catador com garrafa de Coca-Cola vazia'
                  fluid={sustentapetPhotoMobile.childImageSharp.fluid}
                />
              </figure>
            </div>
            <div className={`${styles.description} ${styles.descriptionRight}`}>
              <h4>
                Valorizamos o trabalho <br />
                do catador de PET, <br />
                para que esse resíduo <br />
                seja tão reciclado quanto <br />
                os demais e complete <br />
                seu ciclo
              </h4>
              <p>
                Na prática, o Sustentapet é um tipo de galpão. Nele, recebemos e compramos os resíduos PET dos catadores
                e, sem nenhum outro intermediário, vendemos para nossos próprios fornecedores de resina 100% reciclada,
                que é a matéria-prima das nossas garrafas. <br />
                <br />
                <strong>mas precisamos lembrar que...</strong>
                <br />
                <br />
                Se não cuidada, a água potável pode se tornar um recurso natural esgotável. Além disso, ela está
                disponível em quantidades diferentes, que variam de lugar para lugar. Foi pensando nisso que decidimos
                ir além e nos comprometemos a cuidar dela em duas frentes:
              </p>
            </div>
          </div>
        </div>
        <div className={styles.sustentapetFronts}>
          <div className={styles.front}>
            <h5>Fazendo com que ela exista em quantidade suficiente</h5>
            <figure className={styles.frontImage}>
              <Image
                alt='Copo com água transbordando'
                fluid={waterAccess.childImageSharp.fluid}
              />
            </figure>
            <div className={styles.frontDescription}>
              <h4>Água + Disponibilidade</h4>
              <p>
                Com o Água+ Disponibilidade, desde 2009, nós e alguns parceiros apoiamos projetos que trabalham na
                preservação dos recursos hídricos do nosso país. Hoje nosso maior projeto está na Amazônia, mas também
                mantemos iniciativas nas regiões próximas às nossas fábricas.
              </p>
            </div>
          </div>
          <div className={styles.front}>
            <h5>Fazendo com que ela chegue aos lugares</h5>
            <figure className={styles.frontImage}>
              <Image
                alt='Copo com água transbordando'
                fluid={availabilityWater.childImageSharp.fluid}
              />
            </figure>
            <div className={styles.frontDescription}>
              <h4>Água+ Acesso</h4>
              <p>
                O Água+ Acesso é uma aliança que nasceu em março de 2017 para levar água até as comunidades rurais do
                país. Hoje, estamos em 8 estados, garantindo água potável para 77 mil pessoas em mais de 200
                comunidades.
              </p>
            </div>
          </div>
        </div>
        <Button
          isMuted
          className={styles.button}
          to={ACCESS_WATER}
        >
          Saiba mais
        </Button>
      </div>
    </section>
  )
}

export { NewCrystalActions }
