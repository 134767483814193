import React from 'react'

import { useStaticQuery, graphql } from 'gatsby'

import { Image } from '../UI'

import styles from './NewCrystalResults.module.css'

function NewCrystalResults() {
  const { resultBottle } = useStaticQuery(
    graphql`
      query NewCrystalResultsQuery {
        resultBottle: file(relativePath: { eq: "nova-crystal/crystal-sem-gas.png" }) {
          childImageSharp {
            fluid(maxWidth: 444) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )
  return (
    <section className={styles.content}>
      <div className={styles.article}>
        <h2 className={`titleLarge ${styles.title}`}>
          Como tudo isso <br />
          se reflete em <br />
          como nos <br />
          apresentamos?
        </h2>
        <figure className={`${styles.image} ${styles.imageMobile}`}>
          <Image
            fluid={resultBottle.childImageSharp.fluid}
            alt='Garrafa de água mineral Crystal sem gás'
          />
        </figure>
        <p className={styles.text}>
          A nossa embalagem é hoje 100% feita de outras garrafas. O nosso caminho para o futuro já está traçado: não
          colocar mais plástico novo no mundo. Somos água e ponto e isso também reflete no nosso visual, que está
          totalmente renovado.
        </p>
      </div>

      <figure className={`${styles.image} ${styles.imageDesktop}`}>
        <Image
          fluid={resultBottle.childImageSharp.fluid}
          alt='Garrafa de água mineral Crystal sem gás'
        />
      </figure>
    </section>
  )
}

export { NewCrystalResults }
